import Icon from '@bugbug/core/components/Icon';
import Link from '@bugbug/core/components/Link';
import { useTranslation } from 'react-i18next';

import ProjectSwitch from '~/components/ProjectSwitch';
import useAppRoutes from '~/hooks/useAppRoutes';
import useModal from '~/hooks/useModal';
import * as organizationSelectors from '~/modules/organization/organization.selectors';
import { useAppSelector } from '~/modules/store';
import { selectIsUserLoggedIn } from '~/modules/user/user.selectors';
import analytics from '~/services/analytics';
import { getDaysLeft } from '~/utils/dates';
import urls from '~/views/urls';

import { HelpMenu } from './HelpMenu';
import * as S from './NavBar.styled';
import { UserMenu } from './UserMenu';

export const NavBar = () => {
  const { t } = useTranslation();
  const appRoute = useAppRoutes('home');
  const organizationId = useAppSelector(organizationSelectors.selectCurrentOrganizationId);
  const isAdmin = useAppSelector(organizationSelectors.selectIsOrganizationAdmin);
  const subscription = useAppSelector(organizationSelectors.selectSubscription);
  const isTrialEnded = useAppSelector(organizationSelectors.selectIsTrialEnded);
  const isLoggedIn = useAppSelector(selectIsUserLoggedIn);
  const modal = useModal();

  const plan = subscription?.plan ?? '';
  const isFree = plan === 'free';
  const subscriptionUrl = appRoute.getRouteUrl('subscription', { organizationId });

  const handleStartTrial = () => modal.show('start_trial');

  const handleTrackUpgrade = () => {
    analytics.trackEvent('subscription_upgraded');
  };

  const renderPlanUpgrade = (shouldUpgrade = true) =>
    shouldUpgrade ? (
      <S.PlanUpgradeLink to={subscriptionUrl} onMouseDown={handleTrackUpgrade}>
        <Icon name="upgrade" /> {t('navbar.upgradePlan.link', 'Upgrade')}
      </S.PlanUpgradeLink>
    ) : (
      <S.PlanUpgradeLink onClick={handleStartTrial}>
        {t('navbar.trial.link', 'Try a paid plan for free')}
      </S.PlanUpgradeLink>
    );

  const renderTrialInfo = () => (
    <S.Item>
      <S.PlanInfo>
        {t('navbar.trial.text', {
          defaultValue_one: '1 day left on free trial',
          defaultValue_other: '{{ count }} days left on free trial',
          count: getDaysLeft(subscription.end),
        })}
        {renderPlanUpgrade()}
      </S.PlanInfo>
    </S.Item>
  );

  const renderFreePlanInfo = () => (
    <S.Item>
      <S.PlanInfo>
        {t('navbar.upgradePlan.text', 'Your plan: {{ plan, uppercase }}', { plan })}
        {renderPlanUpgrade(subscription.isTrial || isTrialEnded)}
      </S.PlanInfo>
    </S.Item>
  );

  if (!isLoggedIn) {
    return null;
  }

  return (
    <S.Container data-testid="NavBar">
      <S.LogoAndSwitchContainer>
        <Link to={urls.home}>
          <S.Logo />
        </Link>
        <ProjectSwitch />
      </S.LogoAndSwitchContainer>
      <S.Content>
        {isAdmin && isFree && !subscription.isTrial && renderFreePlanInfo()}
        {isAdmin && subscription.isTrial && renderTrialInfo()}
        <S.Item full>
          <HelpMenu />
        </S.Item>
        <S.Item full>
          <UserMenu />
        </S.Item>
      </S.Content>
    </S.Container>
  );
};
