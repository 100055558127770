import { DropdownLinkItem, DropdownItem } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import { useTranslation } from 'react-i18next';

import type React from 'react';

import type { DropdownItemProps } from '@bugbug/core/components/Dropdown';

import {
  CreateIcon,
  CreateButton,
  Dropdown,
  IconPlaceholder,
  ItemCheckIcon,
  EditButton,
  OptionsContainer,
} from './DropdownPicker.styled';

interface ItemProps {
  id: string | number;
  name: string;
  onEdit: () => void;
  onClick: () => void;
  to: string;
}

interface DropdownPickerProps {
  className?: string;
  header?: React.FC;
  onCreateClick?: () => void;
  items: ItemProps[];
  onCreateLabel?: React.ReactNode;
  value: Pick<ItemProps, 'id' | 'name'>;
}

const DropdownPicker: React.FC<DropdownPickerProps> = ({
  className,
  items = [],
  onCreateClick,
  header = null,
  onCreateLabel = '',
  value = {},
  ...dropdownProps
}) => {
  const { t } = useTranslation();

  // eslint-disable-next-line react/prop-types
  const renderItem = ({ id, name, onEdit, onClick, to }) => {
    const Item: React.FC<DropdownItemProps> = to ? DropdownLinkItem : DropdownItem;
    return (
      <Item key={id} onClick={onClick} to={to}>
        {value.id === id ? <ItemCheckIcon /> : <IconPlaceholder />}
        {name}
        {onEdit && (
          <EditButton onClick={onEdit} role="button">
            <Icon name="edit" />
          </EditButton>
        )}
      </Item>
    );
  };

  const renderCreateButton = () => (
    <CreateButton onClick={onCreateClick}>
      <CreateIcon />
      {onCreateLabel || t('dropdownPicker.create', 'Create')}
    </CreateButton>
  );

  return (
    <Dropdown {...dropdownProps} label={value.name} className={className}>
      {header}
      <OptionsContainer>{items.map(renderItem)}</OptionsContainer>
      {renderCreateButton()}
    </Dropdown>
  );
};

export default DropdownPicker;
