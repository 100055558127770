import { LockIcon } from '@bugbug/core/theme/icons';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import ErrorPage from '~/components/ErrorPage';

const Forbidden = ({ message, buttonLabel, onButtonClick }) => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet title={t('forbidden.pageTitle', 'Access forbidden')} />
      <ErrorPage
        Image={LockIcon}
        message={
          message ||
          t('forbidden.default.message', 'You have not required permissions to see this page.')
        }
        buttonLabel={buttonLabel}
        onButtonClick={onButtonClick}
      />
    </>
  );
};

Forbidden.propTypes = {
  message: PropTypes.string,
  buttonLabel: PropTypes.string,
  onButtonClick: PropTypes.func,
};

Forbidden.defaultProps = {
  message: '',
  buttonLabel: '',
  onButtonClick: Function.prototype,
};

export default Forbidden;
