import { COLOR, FONT_WEIGHT } from '@bugbug/core/theme';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 4px 14px 8px;
  border-top: 1px solid ${COLOR.GRAY_9};
  background-color: ${COLOR.GRAY_21};
  color: ${COLOR.GRAY_12};
  display: flex;
  flex-direction: column;
`;

export const Item = styled.div`
  padding-top: 2px;
  padding-bottom: 2px;
  color: ${COLOR.GRAY_13};
  font-size: 12px;
  line-height: 14px;
  font-weight: ${FONT_WEIGHT.MEDIUM};
  letter-spacing: 0em;
  white-space: nowrap;
`;
