import { DropdownItem } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { IconName } from '@bugbug/core/types/icons';
import useActionState from '~/hooks/useActionState';
import { UserActions } from '~/modules/user/user.redux';

import VersionInfo from '../VersionInfo';

import { TUTORIAL_INFO_TTL } from './Navbar.constants';
import * as S from './NavBar.styled';

interface HelpAction {
  id: string;
  iconName: IconName;
  label: string;
  onClick: () => void;
}

export const HelpMenu = () => {
  const { t } = useTranslation();
  const [showTutorialInfo, setShowTutorialInfo] = useState(false);
  const showTutorialInfoTimeout = useRef<NodeJS.Timeout>();

  const openContact = () => window.open(t('default.urls.contact'));
  const openChangelog = () => window.open(t('default.urls.changelog'));
  const openFeedback = () => window.open(t('default.urls.feedback'));
  const openDocs = () => window.open(t('default.urls.docs'));
  const openInbox = () => window.open(t('default.urls.inbox'));
  const openDemoSass = () => window.open(t('default.urls.demoSass'));
  const bookMeeting = () => window.open(t('default.urls.bookDemoMeeting'));

  const handleTutorialClose = useCallback(() => {
    setShowTutorialInfo(true);
    showTutorialInfoTimeout.current = setTimeout(() => {
      setShowTutorialInfo(false);
    }, TUTORIAL_INFO_TTL);
  }, []);

  useActionState(UserActions.setTutorialVisibilityRequest, {
    onSuccess: handleTutorialClose,
    reqId: 'skip',
  });

  useEffect(() => () => clearTimeout(showTutorialInfoTimeout.current));

  const helpActions: HelpAction[] = [
    {
      id: 'documentation',
      iconName: 'file',
      label: t('navbar.helpActions.documentation', 'Documentation'),
      onClick: openDocs,
    },
    {
      id: 'inbox',
      iconName: 'contact',
      label: t('navbar.helpActions.inbox', 'Testing Inbox'),
      onClick: openInbox,
    },
    {
      id: 'sass',
      iconName: 'monitor',
      label: t('navbar.helpActions.demoSass', 'Example SaaS App'),
      onClick: openDemoSass,
    },
    {
      id: 'changelog',
      iconName: 'announcement',
      label: t('navbar.helpActions.changelog', 'Latest changes'),
      onClick: openChangelog,
    },
    {
      id: 'feedback',
      iconName: 'chatFeedback',
      label: t('navbar.helpActions.feedback', 'Give us feedback'),
      onClick: openFeedback,
    },
    {
      id: 'support',
      iconName: 'send',
      label: t('navbar.userActions.support', 'Contact support'),
      onClick: openContact,
    },
    {
      id: 'demo-meeting',
      iconName: 'calendar',
      label: t('navbar.userActions.bookDemo', 'Book a demo meeting'),
      onClick: bookMeeting,
    },
  ];

  const renderDropdownItem = (config) => {
    const { id, iconName, label = '', onClick } = config;
    return (
      <DropdownItem key={id} onClick={onClick}>
        <Icon name={iconName} />
        {label}
      </DropdownItem>
    );
  };

  return (
    <>
      <S.Dropdown data-testid="HelpDropdown" label={<S.HelpDropdownIcon />}>
        {helpActions.map(renderDropdownItem)}
        <VersionInfo />
      </S.Dropdown>
      {showTutorialInfo && (
        <S.TutorialInfo>
          {t(
            'navbar.userActions.tutorialInfo',
            'If you will need any help, you can open tutorial here',
          )}
        </S.TutorialInfo>
      )}
    </>
  );
};
