import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import config from '~/modules/config';
import {
  selectExtensionVersion,
  selectIsExtensionConnected,
} from '~/modules/extension/extension.selectors';

import { Container, Item } from './VersionInfo.styled';

const VersionInfo = ({ className }) => {
  const { t } = useTranslation();
  const isExtensionConnected = useSelector(selectIsExtensionConnected);
  const activeExtensionVersion = useSelector(selectExtensionVersion);
  const extensionVersion = isExtensionConnected
    ? activeExtensionVersion
    : t('versionInfo.extension.connection', 'Disconnected');

  return (
    <Container className={className}>
      <Item>{t('versionInfo.webApp', 'Web App: {{ version }}', { version: config.VERSION })}</Item>
      <Item>
        {t('versionInfo.extension.version', 'Extension: {{ version }}', {
          version: extensionVersion,
        })}
      </Item>
    </Container>
  );
};

VersionInfo.defaultProps = {
  className: null,
};

VersionInfo.propTypes = {
  className: PropTypes.string,
};

export default VersionInfo;
