import { DropdownItem } from '@bugbug/core/components/Dropdown';
import Icon from '@bugbug/core/components/Icon';
import Tooltip, { PointerEventsCatcher } from '@bugbug/core/components/Tooltip';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useAppRoutes from '~/hooks/useAppRoutes';
import useModal from '~/hooks/useModal';
import { QUERY_ACTION } from '~/modules/constans';
import * as organizationSelectors from '~/modules/organization/organization.selectors';
import { useAppDispatch, useAppSelector } from '~/modules/store';
import { UserActions } from '~/modules/user/user.redux';
import analytics from '~/services/analytics';

import AccountInfo from '../AccountInfo';

import * as S from './NavBar.styled';

export const UserMenu = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'navbar.userActions' });
  const dispatch = useAppDispatch();
  const appRoute = useAppRoutes('home');
  const modal = useModal();

  const organizationId = useAppSelector(organizationSelectors.selectCurrentOrganizationId);
  const isAdmin = useAppSelector(organizationSelectors.selectIsOrganizationAdmin);

  const logout = useCallback(() => {
    analytics.trackEvent('sign_out');
    dispatch(UserActions.logoutRequest());
  }, [dispatch]);

  const goToOrganizationSettings = useCallback(
    () => appRoute.push('organizationGeneral', { organizationId }),
    [appRoute, organizationId],
  );

  const goToSubscription = useCallback(
    () => appRoute.push('subscription', { organizationId }),
    [organizationId, appRoute],
  );

  const goToAccountSettings = useCallback(() => appRoute.push('accountGeneral'), [appRoute]);

  const goToUsers = useCallback(
    () => appRoute.push('users', { organizationId }, { action: QUERY_ACTION.SHOW_USER_INVITATION }),
    [appRoute, organizationId],
  );

  const handleSwitchOrganization = useCallback(() => modal.show('switch_organization'), [modal]);

  // eslint-disable-next-line react/prop-types
  const LimitedItem = useCallback(
    ({ tooltipContent, ...dropdownProps }) => (
      <Tooltip anchor="left-center" content={tooltipContent}>
        <PointerEventsCatcher>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <DropdownItem {...dropdownProps} disabled />
        </PointerEventsCatcher>
      </Tooltip>
    ),
    [],
  );

  const userActions = useMemo(
    () => [
      {
        id: 'organizationSettings',
        iconName: 'organization',
        label: t('organizationSettings', 'Organization settings'),
        tooltipContent: t('requiredPermission', 'Available only for admins'),
        Component: !isAdmin ? LimitedItem : undefined,
        onClick: goToOrganizationSettings,
      },
      {
        id: 'invitePeople',
        iconName: 'userSimple',
        label: t('accountSettings', 'Account settings'),
        onClick: goToAccountSettings,
      },
      {
        id: 'accountSettings',
        iconName: 'addUser',
        label: t('inviteUser', 'Invite people'),
        onClick: goToUsers,
      },
      {
        id: 'subscription',
        iconName: 'money',
        label: t('subscription', 'Subscription'),
        onClick: goToSubscription,
      },
      {
        id: 'switchOrganization',
        iconName: 'switch',
        label: t('switchOrganization', 'Switch organization'),
        onClick: handleSwitchOrganization,
      },
      {
        id: 'signOut',
        iconName: 'logout',
        label: t('signOut', 'Sign out'),
        onClick: logout,
      },
    ],
    [
      t,
      handleSwitchOrganization,
      isAdmin,
      LimitedItem,
      goToOrganizationSettings,
      goToAccountSettings,
      goToSubscription,
      logout,
      goToUsers,
    ],
  );

  const renderDropdownItem = (config) => {
    const { id, iconName, label = '', onClick, Component = DropdownItem, tooltipContent } = config;
    return (
      <Component key={id} onClick={onClick} tooltipContent={tooltipContent}>
        <Icon name={iconName} />
        {label}
      </Component>
    );
  };

  return (
    <S.Dropdown data-testid="UserActionsDropdown" label={<S.UserActionsDropdownIcon />}>
      <AccountInfo />
      {userActions.map(renderDropdownItem)}
    </S.Dropdown>
  );
};
