import { COLOR } from '@bugbug/core/theme/colors';
import { FONT_WEIGHT } from '@bugbug/core/theme/fonts';
import mixins from '@bugbug/core/theme/mixins';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 8px 12px 6px 12px;
  border-bottom: 1px solid ${COLOR.GRAY_9};
  background-color: ${COLOR.GRAY_37};
  color: ${COLOR.GRAY_36};
  line-height: 18.98px;
`;

export const Username = styled.div`
  ${mixins.textEllipsis};
  font-weight: ${FONT_WEIGHT.NORMAL};
  letter-spacing: 0;
`;
export const Organization = styled.div`
  ${mixins.textEllipsis};
  max-width: 160px;
  display: block;
  font-weight: ${FONT_WEIGHT.BOLD};
`;
